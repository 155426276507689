<template>
  <v-container>
    <v-row>
      <v-card-title class="primary--text">Interest questions</v-card-title>
      <v-spacer></v-spacer>
      <v-btn elevation="0" class="px-10" link :to="{name: 'interestsControl', params: {action: 'add'}}" rounded large color="primary white--text">ADD NEW question</v-btn>
    </v-row>
    <!-- <v-row>
      <v-col v-for="item in questions" :key="item.id" cols="7"> -->
        <v-card v-for="item in questions" :key="item.id" class="pa-5 my-5">
          <v-row class="justify-end">
            <v-btn @click="deletingItemId = item.id; dialogDelete = true" small text color="primary">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn
              @click=" useQuestion(item);editingQuestion = item.question; edit = item.id; baseEditingQuestion = item.question;"
              v-if="edit !== item.id" small text color="primary" class="ml-1">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <!-- <v-btn text color="primary" small class="ml-1">
              <v-icon>mdi-link-variant</v-icon>
            </v-btn> -->
          </v-row>
          <v-row v-if="edit !== item.id" class="mx-auto headline mb-5">{{ item.question }}</v-row>
          <div v-else>
            <label class="primary--text">Question</label>
            <v-row class="mx-auto mt-3 text-bold">
              <v-text-field v-model="editingQuestion" outlined></v-text-field>
            </v-row>
            <v-row v-for="(ans,index) in item.answers" :key="ans.id" class="mx-auto mt-3">
              <v-text-field :rules="[rules.required]" outlined v-model="editingAnswers[index].answer"></v-text-field>
            </v-row>
            <!-- <div v-if="4 - item.answers.length > 0">
              <v-row v-for="n in 4 - item.answers.length" :key="n" class="mx-auto mt-3">
                <v-text-field outlined v-model="editingAnswers[item.answers.length + n].answer"></v-text-field>
              </v-row>
            </div> -->
            <v-btn @click="editInterestQuestion(item.id)" color="primary">Submit</v-btn>
            <v-btn text color="primary" @click="edit = 0">Cancel</v-btn>
          </div>
          <div v-if="edit !== item.id">
            <div class="mt-3" v-for="(answer,index) in item.answers" :key="answer.id">
              <label class="primary--text text-h6">Answer {{ index + 1 }}</label>
              <div class="mx-auto text-h6">{{ answer.Answer }}</div>
              <v-divider class="my-2" v-if="index < item.answers.length - 1"></v-divider>
            </div>
          </div>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5 paragraph--text">Are you sure you want to delete this item?</v-card-title>
            <v-divider class="my-3"></v-divider>
            <v-card-actions>
              <v-btn color="warning" text @click="dialogDelete = false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" tile elevation="0" :loading="deleteBtnLoad" :disabled="deleteBtnLoad" @click="deleteQuestion(deletingItemId)">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- </v-col>
    </v-row> -->
    <!-- <v-row v-if="add === 1">
      <v-col cols="9">
        <v-card class="pa-3">
          <v-form ref="addQuestion" v-model="valid">
            <label class="primary--text">Question</label>
            <v-text-field class="mt-2" v-model="newQuestion" outlined></v-text-field>
            <div v-for="(item, index) in answers" :key="index">
              <v-row>
                <v-col cols="10">
                  <label class="primary--text">Answer {{ index + 1 }}</label>
                  <v-text-field v-model="item.answer" outlined :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn color="primary" class="mt-8" @click="removeField(index)">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-btn text color="primary" @click="addTextField">
              <v-icon>mdi-plus-circle</v-icon>
              Add another answer
            </v-btn>
          </v-form>
        </v-card>
        <div class="text-center">
        <v-btn class="mx-auto mt-2" color="primary" @click="addQuestion">Submit</v-btn>
        <v-btn text color="primary" @click="add = 0; newQuestion = null">Cancel</v-btn>
        </div>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>

import admin from '@/web_services/admin'

export default {
  data () {
    return {
      questions: [],
      add: 0,
      edit: 0,
      answers: [],
      dialogDelete: false,
      deletingItemId: null,
      deleteBtnLoad: null,
      newQuestion: null,
      valid: null,
      editingQuestion: null,
      baseEditingQuestion: null,
      editingAnswers: [],
      baseEditingAnswers: [],
      rules: { required: value => !!value || 'This field Required.' }
    }
  },
  methods: {
    useQuestion (item) {
      const answers = []
      item.answers.forEach(function (value) {
        answers.push({
          id: value.id,
          answer: value.Answer
        })
      })
      this.editingAnswers = answers
      this.baseEditingAnswers = answers
    },
    editInterestQuestion (id) {
      const data = {
        interest_question: this.editingQuestion,
        answers: this.editingAnswers
      }
      admin.updateInterestQuestion(data, id).then(res => {
        this.questions = res.data.data
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      }).finally(() => {
        this.edit = 0
      })
    },
    addTextField () {
      this.answers.push({
        answer: ''
      })
    },
    removeField (index) {
      this.answers.splice(index, 1)
    },
    addQuestion () {
      if (this.$refs.addQuestion.validate()) {
        if (this.valid) {
          const data = {
            interest_question: this.newQuestion,
            answers: this.answers
          }
          admin.addInterestQuestion(data).then(res => {
            this.questions = res.data.data
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          }).finally(() => {
            this.add = 0
            this.answers = []
          })
        }
      }
    },
    deleteQuestion (id) {
      this.deleteBtnLoad = true
      admin.deleteInterestQuestion(id).then(res => {
        this.questions = res.data.data
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      }).finally(() => {
        this.deleteBtnLoad = false
        this.dialogDelete = false
      })
    }
  },
  created () {
    admin.interestQuestions().then(res => {
      this.questions = res.data.data
    })
  }
}
</script>
